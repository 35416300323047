import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LineChart from "./LineChart";
import classNames from "classnames";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_TEMP_REQUEST, FETCH_PULSEOX_REQUEST } from "../../Actions";
import ExposureCard from "./ExposureCard";
import SymptomsCard from "./SymptomsCard";
import RiskFactorsCard from "./RiskFactorsCard";

function checkNull(value) {
  if (value == null) {
    return "-";
  } else {
    return value;
  }
}

function PersonalDashboard(props) {
  const dispatch = useDispatch();
  let { id } = useParams();
  const temp_fetching = useSelector(
    (state) => state.personalChart.temp_fetching
  );
  const pulseOx_fetching = useSelector(
    (state) => state.personalChart.pulseOx_fetching
  );
  const temp_chart_data = useSelector(
    (state) => state.personalChart.temp_chart_data
  );
  const pulse_chart_data = useSelector(
    (state) => state.personalChart.pulse_chart_data
  );
  const spo2_chart_data = useSelector(
    (state) => state.personalChart.spo2_chart_data
  );

  const d = props.allData.find((obj) => obj.uid === id);
  const { exposures, symptoms, riskFactors } = d;

  useEffect(() => {
    dispatch({ type: FETCH_TEMP_REQUEST, uid: d.uid });
    dispatch({ type: FETCH_PULSEOX_REQUEST, uid: d.uid });
  }, [d.uid, dispatch]);

  const Summary = () => {
    const summary_data = [
      {
        type: "Priority",
        value: d.priority,
      },
      {
        type: "Days since last measurement",
        value: d.days_last_active,
        status: d.activity_status,
      },
      {
        type: "Temperature ( 24h Avg. )",
        value: d.temp_avg == null ? "-" : d.temp_avg + " °F",
      },
      {
        type: "Heart rate ( 24h Avg. )",
        value: d.hr_avg == null ? "-" : d.hr_avg + " bpm",
      },
      {
        type: "Spo2 avg ( 24h Min. )",
        value: d.spo2Min24 == null ? "-" : d.spo2Min24 + " %",
      },
    ];

    const listItems = summary_data.map((e) => (
      <div className="p-grid" key={e.type}>
        <div className="p-col-8">
          <p>{e.type}</p>
        </div>
        <div className="p-col-4" style={{ fontWeight: 600 }}>
          {e.type === "Priority" ? (
            priorityTag(e.value)
          ) : e.type === "Days since last measurement" ? (
            activityTag(e.value, e.status)
          ) : (
            <p>{e.value}</p>
          )}
        </div>
      </div>
    ));
    return (
      <div className="card">
        <h1 style={{ fontSize: "16px" }}>Summary</h1>
        {listItems}
      </div>
    );
  };

  const priorityTag = (priority) => {
    if (priority === 0) {
      return (
        <div className={classNames("priority-box", "priority-emergency")}>
          Emergency
        </div>
      );
    }
    return (
      <div
        className={classNames("priority-box", "priority-" + String(priority))}
      >
        priority
        {priority}
      </div>
    );
  };

  const activityTag = (days, status) => {
    return (
      <div className={classNames("activity-box", String(status))}>
        {days <= 1
          ? "today"
          : days > 5000
          ? "inactive"
          : String(days) + " days"}
      </div>
    );
  };

  const Info = () => {
    const info_data = [
      { type: "Name", value: d.name },
      {
        type: "Age",
        value: checkNull(d.age),
      },
      {
        type: "Gender",
        value: checkNull(d.gender),
      },
      {
        type: "Phone",
        value: checkNull(d.phone),
      },
      {
        type: "Zipcode",
        value: checkNull(d.zipCode),
      },
    ];
    const listItems = info_data.map((e) => (
      <div className="p-grid" key={e.type}>
        <div className="p-col-8">
          <p>{e.type}</p>
        </div>
        <div className="p-col-4" style={{ fontWeight: 600 }}>
          <p>{e.value}</p>
        </div>
      </div>
    ));
    return (
      <div className="card">
        <h1 style={{ fontSize: "16px" }}>Account {d.email}</h1>
        {listItems}
      </div>
    );
  };

  return (
    <div className="p-col-12 p-lg-12">
      <div className="p-grid p-fluid">
        <Button
          label="Return to dashboard"
          icon="pi pi-chevron-left"
          onClick={props.onBackButtonClicked}
          style={{ padding: "5px", backgroundColor: "#454750", fontSize: 16 }}
        />
        <div className="p-col-6 p-lg-6">
          <Summary />
        </div>
        <div className="p-col-6 p-lg-6">
          <Info />
        </div>
        <div className="p-col-6 p-lg-6">
          <ExposureCard userUid={d.uid} data={exposures} />
        </div>
        <div className="p-col-6 p-lg-6">
          <SymptomsCard userUid={d.uid} data={symptoms} />
        </div>
        <div className="p-col-6 p-lg-6">
          <RiskFactorsCard userUid={d.uid} data={riskFactors} />
        </div>

        <LineChart
          chartTitle="Heart rate (beats/min)"
          data={pulse_chart_data}
          loading={pulseOx_fetching}
          min_y={40}
          main_color="rgb(247, 143, 179, 0.8)"
          sencond_color="rgb(248, 165, 194, 0.2)"
        />
        <LineChart
          chartTitle="SPO2 (%)"
          data={spo2_chart_data}
          loading={pulseOx_fetching}
          min_y={85}
          main_color="rgb(113, 88, 226, 0.8)"
          sencond_color="rgb(125, 95, 255, 0.3)"
        />
        <LineChart
          chartTitle="Temperature (F)"
          data={temp_chart_data}
          loading={temp_fetching}
          min_y={80}
          main_color="rgb(249, 127, 81)"
          sencond_color="rgb(254, 164, 127, 0.2)"
        />
      </div>
    </div>
  );
}

export default PersonalDashboard;
