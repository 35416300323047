import React from "react";
import { useSelector } from "react-redux";

function HighlightBoxes() {
  const fetching = useSelector((state) => state.tableData.fetching);
  const updating = useSelector((state) => state.tableData.updating_priorities);
  const totalPatients = useSelector((state) => state.tableData.totalPatients);
  const countEmergency = useSelector((state) => state.tableData.countEmergency);
  const countPriority1 = useSelector((state) => state.tableData.countPriority1);
  const countPriority2 = useSelector((state) => state.tableData.countPriority2);
  const countPriority3 = useSelector((state) => state.tableData.countPriority3);
  const countPriority4 = useSelector((state) => state.tableData.countPriority4);
  const countPriority5 = useSelector((state) => state.tableData.countPriority5);

  return (
    <>
      <div className="p-col-12 p-md-6 p-xl-3">
        <div className="highlight-box">
          <div
            className="initials"
            style={{
              backgroundColor: "#2f3542",
              color: "#a4b0be",
            }}
          >
            <span>T</span>
          </div>
          <div className="highlight-details ">
            <span>Total</span>
            <span className="count">{fetching ? "-" : totalPatients}</span>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-6 p-xl-3">
        <div className="highlight-box">
          <div
            className="initials"
            style={{
              backgroundColor: "rgb(255, 117, 131)",
              color: "rgb(197, 14, 14)",
            }}
          >
            <span>E</span>
          </div>
          <div className="highlight-details ">
            <span>Emergency</span>
            <span className="count">{updating ? "-" : countEmergency}</span>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-6 p-xl-3">
        <div className="highlight-box">
          <div
            className="initials"
            style={{
              backgroundColor: "rgb(255, 219, 140)",
              color: "rgb(138, 66, 64)",
            }}
          >
            <span>P1-3</span>
          </div>
          <div className="highlight-details ">
            <span>Priority 1 to 3</span>
            <span className="count">
              {updating
                ? "-"
                : countPriority1 + countPriority2 + countPriority3}
            </span>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-6 p-xl-3">
        <div className="highlight-box">
          <div
            className="initials"
            style={{ backgroundColor: "#b3e5fc", color: "#23547b" }}
          >
            <span>P4-5</span>
          </div>
          <div className="highlight-details ">
            <span>Priority 4 to 5</span>
            <span className="count">
              {updating ? "-" : countPriority4 + countPriority5}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default HighlightBoxes;
