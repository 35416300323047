import React, {Component} from 'react';
import {connect} from 'react-redux';
import QuestionEditor from './QuestionEditor';
import {Button} from 'primereact/button';
import { restoreDefaultPatientInputs } from '../../Actions';

class PatientInputs extends Component {
  render () {
    return (
      <div className="p-grid">
        <div className='p-col'>
          <Button 
            label='Restore Defaults'
            className='p-button-primary'
            onClick={this.props.restoreDefaults}
            style={{margin:'0px 8px'}}/>
        </div>
        <div className="p-col-12">
          <div className="card">
              <h1>Exposure</h1>
              <QuestionEditor type='exposure' data={this.props.exposureData}/>
          </div>
          <div className="card">
              <h1>Symptoms</h1>
              <QuestionEditor type='symptoms' data={this.props.symptomData}/>
          </div>
          <div className="card">
              <h1>Risk Factors</h1>
              <QuestionEditor type='risk factors' data={this.props.riskFactorData}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  exposureData: state.patientInputs.exposure,
  symptomData: state.patientInputs.symptoms,
  riskFactorData: state.patientInputs.riskFactors,
})

const mapDispatchToProps = dispatch => ({
  restoreDefaults: () => dispatch(restoreDefaultPatientInputs())
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientInputs)