export const FETCH_TEMP_REQUEST = "FETCH_TEMP_REQUEST";
export const FETCH_TEMP_SUCCESS = "FETCH_TEMP_SUCCESS";
export const FETCH_TEMP_FAILURE = "FETCH_TABLE_DATA_FAILURE";
export const FETCH_PULSEOX_REQUEST = "FETCH_PULSEOX_REQUEST";
export const FETCH_PULSEOX_SUCCESS = "FETCH_PULSEOX_SUCCESS";
export const FETCH_PULSEOX_FAILURE = "FETCH_PULSEOX_FAILURE";

export function fetchTempRequest(uid) {
  return { type: FETCH_TEMP_REQUEST, uid: uid };
}
export function fetchTempSuccess(data) {
  return { type: FETCH_TEMP_SUCCESS, data: data };
}
export function fetchTempFailure(error) {
  return { type: FETCH_TEMP_FAILURE, error: error };
}
export function fetchPulseOxRequest(uid) {
  return { type: FETCH_PULSEOX_REQUEST, uid: uid };
}
export function fetchPulseOxSuccess(pulse_data, spo2_data) {
  return { type: FETCH_PULSEOX_SUCCESS, pulse_data, spo2_data };
}
export function fetchPulseOxFailure(error) {
  return { type: FETCH_PULSEOX_FAILURE, error: error };
}
