import produce from "immer";
import { ADD_TODO } from "../Actions";

export const initialState = {
  todo: null,
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_TODO:
        draft.todo = action.todo;
        break;
      default:
        break;
    }
  });
