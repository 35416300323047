import React from "react";

function Footer() {
  return (
    <div className="layout-footer">
      <span className="footer-text" style={{ marginRight: "5px" }}>
        Patchd, Inc. 2020
      </span>
    </div>
  );
}

export default Footer;
