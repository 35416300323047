import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import classNames from "classnames";
import { MultiSelect } from "primereact/multiselect";
import HighlightBoxes from "./HighlightBoxes";

export const STATUS_OPTIONS = ["Needs Action", "Processed", "Ignore"];

class PatientTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPatient: null,
      statusFilters: ["No Filter", ...STATUS_OPTIONS],
      selectedStatus: null,
      seletedPriority: null,
      selectedActivityStatus: null,
    };

    this.priorities = [
      { name: "EMERGENCY", value: 0 },
      { name: "PRIORITY1", value: 1 },
      { name: "PRIORITY2", value: 2 },
      { name: "PRIORITY3", value: 3 },
      { name: "PRIORITY4", value: 4 },
      { name: "PRIORITY5", value: 5 },
    ];

    this.activityStatus = [
      { name: "TODAY", value: "today" },
      { name: "THIS WEEK", value: "this_week" },
      { name: "LAST WEEK", value: "last_week" },
      { name: "THIS MONTH", value: "this_month" },
      { name: "THIS YEAR", value: "year" },
      { name: "INACTIVE", value: "inactive" },
    ];

    //bodycell
    this.ageTemplate = this.ageTemplate.bind(this);
    this.spo2BodyTemplate = this.spo2BodyTemplate.bind(this);
    this.tempBodyTemplate = this.tempBodyTemplate.bind(this);
    this.hrBodyTemplate = this.hrBodyTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.statusEditor = this.statusEditor.bind(this);
    this.priorityBodyTemplate = this.priorityBodyTemplate.bind(this);
    this.activityBodyTemplate = this.activityBodyTemplate.bind(this);
    //filter
    this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
    this.onPriorityStatusFilterChange = this.onPriorityStatusFilterChange.bind(
      this
    );
    this.onActivityStatusFilterChange = this.onActivityStatusFilterChange.bind(
      this
    );
  }

  renderStatusFilter() {
    return (
      <Dropdown
        value={this.state.selectedStatus}
        options={this.state.statusFilters}
        onChange={this.onStatusFilterChange}
        placeholder="Select a Status"
      />
    );
  }

  onStatusFilterChange(event) {
    if (event.value !== "No Filter") {
      this.dt.filter(event.value, "status", "equals");
    } else {
      this.dt.filter("", "status", "contains");
    }
    this.setState({ selectedStatus: event.value });
  }

  onPriorityStatusFilterChange(event) {
    this.dt.filter(event.value, "priority", "in");
    this.setState({ seletedPriority: event.value });
  }

  onActivityStatusFilterChange(event) {
    this.dt.filter(event.value, "activity_status", "in");
    this.setState({ selectedActivityStatus: event.value });
  }

  priorityBodyTemplate(rowData) {
    if (rowData.priority === 0) {
      return (
        <div className={classNames("priority-box", "priority-emergency")}>
          Emergency
        </div>
      );
    }
    return (
      <div
        className={classNames(
          "priority-box",
          "priority-" + String(rowData.priority)
        )}
      >
        priority
        {rowData.priority}
      </div>
    );
  }

  activityBodyTemplate(rowData) {
    return (
      <div
        className={classNames("activity-box", String(rowData.activity_status))}
      >
        {String(rowData.activity_status).replace("_", " ")}
      </div>
    );
  }

  ageTemplate(rowData) {
    return (
      <div className="p-grid">
        <div className="p-col-12 measurement-age">{rowData.age}</div>
      </div>
    );
  }

  spo2BodyTemplate(rowData) {
    return (
      <div className="p-grid measurement-template">
        <div className="p-col-6 measurement-data-latest">
          {rowData.spo2_latest}
        </div>
        <div className="p-col-6 measurement-data-avg">
          ({rowData.spo2Min24})
        </div>
      </div>
    );
  }

  tempBodyTemplate(rowData) {
    return (
      <div className="p-grid p-dir-row p-justify-end measurement-template">
        <div className="p-col-6 measurement-data-latest">
          {rowData.temp_latest}
        </div>
        <div className="p-col-6 measurement-data-avg">({rowData.temp_avg})</div>
      </div>
    );
  }

  hrBodyTemplate(rowData) {
    return (
      <div className="p-grid p-dir-row p-justify-end measurement-template">
        <div className="p-col-6 measurement-data-latest">
          {rowData.hr_latest}
        </div>
        <div className="p-col-6 measurement-data-avg">({rowData.hr_avg})</div>
      </div>
    );
  }

  actionBodyTemplate(rowData) {
    return (
      <div className="p-grid p-align-center">
        <div className="p-col">
          <Button
            label="More Info"
            icon="pi pi-chevron-right"
            iconPos="right"
            className="p-button-secondary"
            onClick={() => this.props.moreInfoClick(rowData)}
          />
        </div>
      </div>
    );
  }

  onEditorValueChange(props, value) {
    this.props.updatePatientStatus(props.rowData.uid, value);
  }

  statusEditor(props) {
    return (
      <Dropdown
        value={props.rowData.status}
        options={STATUS_OPTIONS}
        onChange={(e) => this.onEditorValueChange(props, e.value)}
        style={{ width: "100%" }}
        placeholder="Select status"
      />
    );
  }

  renderPriorityFilter() {
    return (
      <MultiSelect
        className="p-column-filter"
        value={this.state.seletedPriority}
        options={this.priorities}
        onChange={this.onPriorityStatusFilterChange}
        itemTemplate={this.prioritiesItemTemplate}
        placeholder="All"
        optionLabel="name"
        optionValue="value"
      />
    );
  }

  renderActivityStatusFilter() {
    return (
      <MultiSelect
        className="p-column-filter"
        value={this.state.selectedActivityStatus}
        options={this.activityStatus}
        onChange={this.onActivityStatusFilterChange}
        itemTemplate={this.activityStatusItemTemplate}
        placeholder="All"
        optionLabel="name"
        optionValue="value"
      />
    );
  }

  activityStatusItemTemplate(options) {
    return (
      <div className={classNames("activity-box", String(options.value))}>
        {options.name}
      </div>
    );
  }

  prioritiesItemTemplate(options) {
    if (options.value === 0) {
      return (
        <div className={classNames("priority-box", "priority-emergency")}>
          Emergency
        </div>
      );
    }
    return (
      <div
        className={classNames(
          "priority-box",
          "priority-" + String(options.value)
        )}
      >
        priority
        {options.value}
      </div>
    );
  }

  render() {
    // const statusFilter = this.renderStatusFilter();
    const priorityFilter = this.renderPriorityFilter();
    const activityStatusFilter = this.renderActivityStatusFilter();
    let headerGroup = (
      <ColumnGroup>
        <Row>
          <Column header="Patient" colSpan={4} />
          <Column header="SPO2 (%)" colSpan={1} />
          <Column header="Temperature (F)" colSpan={1} />
          <Column header="Heart Rate" colSpan={1} />
          <Column />
        </Row>
        <Row>
          <Column
            header="Name"
            field="name"
            filter
            filterMatchMode="contains"
            filterPlaceholder="Search name"
          />
          <Column
            header="Priority"
            sortField="priority"
            filterField="priority"
            sortable
            filter
            filterElement={priorityFilter}
            // filterMatchMode="contains"
            // filterPlaceholder="Search priority"
          />
          <Column
            header="Last Measurement"
            sortField="days_last_active"
            filterField="activity_status"
            sortable
            filter
            filterElement={activityStatusFilter}
          />
          <Column
            header="Age"
            field="age"
            filter
            filterMatchMode="gte"
            filterPlaceholder="Age above"
          />
          {/* <Column
            header="Status"
            field="status"
            filter
            filterElement={statusFilter}
          /> */}
          <Column
            header="Latest (24h Min)"
            field="spo2_latest"
            sortable
            filter
            filterMatchMode="lte"
            filterPlaceholder="SPO2 below"
          />
          <Column
            header="Latest (24h Max)"
            field="temp_latest"
            sortable
            filter
            filterMatchMode="gt"
            filterPlaceholder="Temp above"
          />
          <Column
            header="Latest (24h avg)"
            field="hr_latest"
            sortable
            filter
            filterMatchMode="gt"
            filterPlaceholder="HR above"
          />
          <Column />
        </Row>
      </ColumnGroup>
    );
    return (
      <>
        <HighlightBoxes />
        {/* <div className="p-col-12 p-lg-12"> */}
        <div className="card patient-table">
          <h1 style={{ fontSize: "16px" }}>All Patients</h1>
          <DataTable
            dataKey="uid"
            ref={(el) => (this.dt = el)}
            value={this.props.data}
            style={{ marginBottom: "20px" }}
            selectionMode="multiple"
            selection={this.state.selectedPatient}
            onSelectionChange={(e) =>
              this.setState({ selectedPatient: e.value })
            }
            paginator
            rows={35}
            headerColumnGroup={headerGroup}
            sortField="priority"
            sortOrder={2}
            rowsPerPageOptions={[35, 50, 100]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          >
            <Column field="name" />
            <Column
              sortField="priority"
              filterField="priority"
              body={this.priorityBodyTemplate}
            />
            <Column
              sortField="days_last_active"
              filterField="activity_status"
              body={this.activityBodyTemplate}
            />
            <Column field="age" body={this.ageTemplate} />
            {/* <Column field="status" editor={this.statusEditor} /> */}
            <Column field="spo2_latest" body={this.spo2BodyTemplate} />
            <Column field="temp_latest" body={this.tempBodyTemplate} />
            <Column field="hr_latest" body={this.hrBodyTemplate} />
            <Column
              body={this.actionBodyTemplate}
              headerStyle={{ width: "8em", textAlign: "center" }}
              bodyStyle={{ textAlign: "center", overflow: "visible" }}
            />
          </DataTable>
        </div>
        {/* </div> */}
      </>
    );
  }
}

export default PatientTable;
