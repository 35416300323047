import React from "react";
import { connect } from "react-redux";
import QueryBuilder from "./QueryBuilder";
// import FeverCard from './FeverCard';
// import {Card} from 'primereact/card';
// import DefinitionCard from './DefinitionCard';
import { Button } from "primereact/button";
import { resetRules } from "../../Actions";

function RulesPage(props) {
  return (
    <div className="p-grid p-dir-col">
      <div className="p-col">
        <Button
          label="Restore Defaults"
          className="p-button-primary"
          onClick={() => props.resetRules()}
          style={{ margin: "0px 8px" }}
        />
        {/* <div className="p-grid">
        <div className="p-col-4">
            <FeverCard />
        </div>
        <div className="p-col-4">
          <Card title='Immunocompromised Definition'>
            A patient is defined as immunocompromised if they fulfil the following criteria
            <DefinitionCard />
          </Card>
        </div>
        <div className="p-col-4">
          <Card title="Comorbidities Definition">
            A patient is defined as having comorbodities that put them at risk if they fulfill the following criteria
            <DefinitionCard />
          </Card>
        </div>
      </div> */}
      </div>
      <div className="p-col">
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <h1>Emergency</h1>
              <QueryBuilder priority={0} rule={props.emergency} />
            </div>
            <div className="card">
              <h1>Priority 1</h1>
              <QueryBuilder priority={1} rule={props.priority1} />
            </div>
            <div className="card">
              <h1>Priority 2</h1>
              <QueryBuilder priority={2} rule={props.priority2} />
            </div>
            <div className="card">
              <h1>Priority 3</h1>
              <QueryBuilder priority={3} rule={props.priority3} />
            </div>
            <div className="card">
              <h1>Priority 4</h1>
              <QueryBuilder priority={4} rule={props.priority4} />
            </div>
            <div className="card">
              <h1>Priority 5</h1>
              <p>
                This is the default priority, if a patient does not meet any of
                the above criteria, they will be given a Priority level of 5.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  emergency: state.rule.emergency,
  priority1: state.rule.priority1,
  priority2: state.rule.priority2,
  priority3: state.rule.priority3,
  priority4: state.rule.priority4,
  priority5: state.rule.priority5,
});

const mapDispatchToProps = (dispatch) => ({
  resetRules: () => dispatch(resetRules()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RulesPage);
