export const SAVE_RULE_EMERGENCY = "SAVE_RULE_EMERGENCY";
export const SAVE_RULE_PRIORITY1 = "SAVE_RULE_PRIORITY1";
export const SAVE_RULE_PRIORITY2 = "SAVE_RULE_PRIORITY2";
export const SAVE_RULE_PRIORITY3 = "SAVE_RULE_PRIORITY3";
export const SAVE_RULE_PRIORITY4 = "SAVE_RULE_PRIORITY4";
export const SAVE_RULE_PRIORITY5 = "SAVE_RULE_PRIORITY5";

export const ADD_EMERGENCY_RULE = "ADD_EMERGENCY_RULE";
export const ADD_PRIORITY_1_RULE = "ADD_PRIORITY_1_RULE";
export const ADD_PRIORITY_2_RULE = "ADD_PRIORITY_2_RULE";
export const ADD_PRIORITY_3_RULE = "ADD_PRIORITY_3_RULE";
export const ADD_PRIORITY_4_RULE = "ADD_PRIORITY_4_RULE";
export const ADD_PRIORITY_5_RULE = "ADD_PRIORITY_5_RULE";

export const REMOVE_EMERGENCY_RULE = "REMOVE_EMERGENCY_RULE";
export const REMOVE_PRIORITY_1_RULE = "REMOVE_PRIORITY_1_RULE";
export const REMOVE_PRIORITY_2_RULE = "REMOVE_PRIORITY_2_RULE";
export const REMOVE_PRIORITY_3_RULE = "REMOVE_PRIORITY_3_RULE";
export const REMOVE_PRIORITY_4_RULE = "REMOVE_PRIORITY_4_RULE";
export const REMOVE_PRIORITY_5_RULE = "REMOVE_PRIORITY_5_RULE";

export const RESET_RULES = "RESET_RULES";

export function saveRule(rule, priority) {
  switch (priority) {
    case 0:
      return {
        type: SAVE_RULE_EMERGENCY,
        rule: rule,
      };
    case 1:
      return {
        type: SAVE_RULE_PRIORITY1,
        rule: rule,
      };
    case 2:
      return {
        type: SAVE_RULE_PRIORITY2,
        rule: rule,
      };
    case 3:
      return {
        type: SAVE_RULE_PRIORITY3,
        rule: rule,
      };
    case 4:
      return {
        type: SAVE_RULE_PRIORITY4,
        rule: rule,
      };
    case 5:
      return {
        type: SAVE_RULE_PRIORITY5,
        rule: rule,
      };
    default:
      return {};
  }
}

export function addRule(rule, priority) {
  switch (priority) {
    case 0:
      return {
        type: ADD_EMERGENCY_RULE,
        rule: rule,
      };
    case 1:
      return {
        type: ADD_PRIORITY_1_RULE,
        rule: rule,
      };
    case 2:
      return {
        type: ADD_PRIORITY_2_RULE,
        rule: rule,
      };
    case 3:
      return {
        type: ADD_PRIORITY_3_RULE,
        rule: rule,
      };
    case 4:
      return {
        type: ADD_PRIORITY_4_RULE,
        rule: rule,
      };
    case 5:
      return {
        type: ADD_PRIORITY_5_RULE,
        rule: rule,
      };
    default:
      return {};
  }
}

export function deleteRule(rule, priority) {
  switch (priority) {
    case 0:
      return {
        type: REMOVE_EMERGENCY_RULE,
        rule: rule,
      };
    case 1:
      return {
        type: REMOVE_PRIORITY_1_RULE,
        rule: rule,
      };
    case 2:
      return {
        type: REMOVE_PRIORITY_2_RULE,
        rule: rule,
      };
    case 3:
      return {
        type: REMOVE_PRIORITY_3_RULE,
        rule: rule,
      };
    case 4:
      return {
        type: REMOVE_PRIORITY_4_RULE,
        rule: rule,
      };
    case 5:
      return {
        type: REMOVE_PRIORITY_5_RULE,
        rule: rule,
      };
    default:
      return {};
  }
}

export function resetRules() {
  return {
    type: RESET_RULES,
  };
}
