import React, { useState } from "react";
import { withRouter } from "react-router";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../layout/layout.scss";

function Signup({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  return (
    <div className="p-grid p-justify-center dark" style={{ height: "100%" }}>
      <div className="p-col-11 p-md-4 centered">
        <Card
          subTitle="Sign up disabled please contact support@patchdmedical.com for account creation"
          title="Patchd at home Dashboard"
          className="radius-20"
        >
          <div className="p-grid p-fluid p-justify-center">
            <div className="p-col-12">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-user"></i>
                </span>
                <InputText
                  disabled
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-grid p-fluid p-justify-center">
            <div className="p-col-12">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-lock"></i>
                </span>
                <Password
                  disabled
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-grid p-fluid p-justify-center">
            <div className="p-col-12">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-lock"></i>
                </span>
                <Password
                  disabled
                  feedback={false}
                  placeholder="Comfirm password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-grid p-fluid p-justify-center">
            <div className="p-col-12">
              <Button
                disabled
                label="Sign up"
                className="p-button-rounded p-button-primary"
                onClick={() => {}}
              />
            </div>
          </div>
          <div className="p-grid p-fluid p-justify-center">
            <div className="p-col-12">
              <Button
                label="Back"
                className="p-button-rounded p-button-warning"
                onClick={() => {
                  history.push("./Login");
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default withRouter(Signup);
