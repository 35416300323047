import produce from "immer";
import {
  FETCH_TEMP_REQUEST,
  FETCH_TEMP_SUCCESS,
  FETCH_TEMP_FAILURE,
  FETCH_PULSEOX_REQUEST,
  FETCH_PULSEOX_SUCCESS,
  FETCH_PULSEOX_FAILURE,
} from "../Actions";

export const initialState = {
  temp_fetching: false,
  pulseOx_fetching: false,
  temp_chart_data: [{ ts: null, value: null }],
  pulse_chart_data: [{ ts: null, value: null }],
  spo2_chart_data: [{ ts: null, value: null }],
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_TEMP_REQUEST:
        draft.temp_fetching = true;
        break;
      case FETCH_TEMP_SUCCESS:
        draft.temp_fetching = false;
        if (action.data.length >= 1) {
          draft.temp_chart_data = action.data;
        } else {
          draft.temp_chart_data = initialState.temp_chart_data;
        }
        break;
      case FETCH_TEMP_FAILURE:
        draft.temp_chart_data = initialState.temp_chart_data;
        draft.temp_fetching = false;
        break;
      case FETCH_PULSEOX_REQUEST:
        draft.pulseOx_fetching = true;
        break;
      case FETCH_PULSEOX_SUCCESS:
        draft.pulseOx_fetching = false;
        if (action.pulse_data.length >= 1) {
          draft.pulse_chart_data = action.pulse_data;
        } else {
          draft.pulse_chart_data = initialState.pulse_chart_data;
        }
        if (action.spo2_data.length >= 1) {
          draft.spo2_chart_data = action.spo2_data;
        } else {
          draft.spo2_chart_data = initialState.spo2_chart_data;
        }
        break;
      case FETCH_PULSEOX_FAILURE:
        draft.spo2_chart_data = initialState.spo2_chart_data;
        draft.pulseOx_fetching = false;
        break;
      default:
        break;
    }
  });
