import React from "react";
import PersonalDashboard from "./PersonalDashboard";
import { useHistory, Route, useRouteMatch, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import DashboardTable from "./DashboardTable";

function Dashboard() {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const fetching = useSelector((state) => state.tableData.fetching);
  const patients = useSelector((state) => state.tableData.patients);

  const handleMoreInfoClicked = (rowData) => {
    const id = rowData.uid;
    history.push(`${url}/${id}`);
  };

  const handleReturnToDashboard = (rowData) => {
    history.goBack();
  };

  return (
    <div className="p-grid p-fluid dashboard">
      {fetching ? (
        <LoadingTable />
      ) : (
        <Switch>
          <Route exact path={path}>
            <DashboardTable
              data={patients}
              moreInfoClick={handleMoreInfoClicked}
            />
          </Route>
          <Route path={`${path}/:id`}>
            <PersonalDashboard
              allData={patients}
              onBackButtonClicked={handleReturnToDashboard}
            />
          </Route>
        </Switch>
      )}
    </div>
  );
}

export default Dashboard;

const LoadingTable = () => {
  return (
    <div className="p-grid p-dir-col loading-container centered">
      <div className="p-col" />
      <div className="p-col p-grid p-dir-col">
        <div className="p-col" />
        <div className="p-col">
          <ProgressSpinner />
        </div>
        <div className="p-col">
          <h2>Loading...</h2>
        </div>
      </div>
      <div className="p-col" />
    </div>
  );
};
