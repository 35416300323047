import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  blacklist: [],
  storage,
};

export default (rootReducer, rootSaga) => {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const sagaMiddleware = createSagaMiddleware();
  const middleware = applyMiddleware(sagaMiddleware);
  const store = createStore(persistedReducer, composeWithDevTools(middleware));
  let sagasManager = sagaMiddleware.run(rootSaga);
  let persistor = persistStore(store);
  return { store, sagasManager, sagaMiddleware, persistor };
};
