export const FETCH_TABLE_DATA_REQUEST = "FETCH_TABLE_DATA_REQUEST";
export const FETCH_TABLE_DATA_SUCCESS = "FETCH_TABLE_DATA_SUCCESS";
export const FETCH_TABLE_DATA_FAILURE = "FETCH_TABLE_DATA_FAILURE";
export const UPDATE_PRIORITIES = "UPDATE_PRIORITIES";
export const UPDATE_PRIORITIES_FINISHED = "UPDATE_PRIORITIES_FINISHED";
export const UPDATE_ESR_REQUEST = "UPDATE_ESR_REQUEST";
export const UPDATE_ESR_SUCCESS = "UPDATE_ESR_SUCCESS";
export const UPDATE_ESR_FAILURE = "UPDATE_ESR_FAILURE";
export const UPDATE_PATIENT_ESR = "UPDATE_PATIENT_ESR";

export function fetchTableDataRequest() {
  return { type: FETCH_TABLE_DATA_REQUEST };
}

export function fetchTableDataSuccess(data) {
  return { type: FETCH_TABLE_DATA_SUCCESS, data: data };
}

export function fetchTableDataFailure(error) {
  return { type: FETCH_TABLE_DATA_FAILURE, error: error };
}

export function updatingPriorities(tableData) {
  return {
    type: UPDATE_PRIORITIES,
    tableData,
  };
}

export function updatePrioritiesFinished(data) {
  return {
    type: UPDATE_PRIORITIES_FINISHED,
    data: data,
  };
}

export function updateESRRequest(data) {
  return {
    type: UPDATE_ESR_REQUEST,
    data: data,
  };
}

export function updateESRSuccess(data) {
  return {
    type: UPDATE_ESR_SUCCESS,
    data: data,
  };
}

export function updateESRFailure(error) {
  return {
    type: UPDATE_ESR_FAILURE,
    error: error,
  };
}

export function updatePatientESR(data) {
  return {
    type: UPDATE_PATIENT_ESR,
    data: data,
  };
}
