import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Query, Builder, Utils as QbUtils} from 'react-awesome-query-builder';
import {DEFAULT_CONFIG} from './config.js'
import { Button } from "primereact/button";
import "antd/dist/antd.css";
import 'react-awesome-query-builder/css/styles.scss';
import {saveRule} from '../../Actions/RuleActions';

class QueryBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tree: QbUtils.checkTree(QbUtils.loadTree(this.props.rule), DEFAULT_CONFIG),
      config: DEFAULT_CONFIG,
      editing: false,
      loadIndicator: 0,
    };
  }
  
  patientInputsToConfig = (inputs) => {
    return Object.assign({}, inputs.map(input => ({
      type: input.type,
      label: input.label,
      valueSources: ['value'],
      excludeOperators: input.type === 'boolean' ? ['not_equal'] : null,
      preferWidgets: input.type === 'number' ? ['number'] : null
  })))
  }

  latestConfig = (inputs, defaultConfig) => {
    let symptoms = this.patientInputsToConfig(inputs.symptoms)
    let exposure = this.patientInputsToConfig(inputs.exposure)
    let riskFactors = this.patientInputsToConfig(inputs.riskFactors)

    var config = {
      ...defaultConfig,
      fields: {
        ...defaultConfig.fields,
        exposures: {
          type: '!struct',
          label: 'Exposures',
          subfields: {...exposure},
        },
        symptoms: {
          type: '!struct',
          label: 'Symptoms',
          subfields: {...symptoms},
        },
        riskFactors: {
          type: '!struct',
          label: 'Risk Factors',
          subfields: {...riskFactors},
        }
      }
    }
    return config
  } 
  
  render = () => (
    <div className='p-grid p-dir-col'>
      <div className='p-col'>
        <Query
          {...this.latestConfig(this.props.patientInputs, DEFAULT_CONFIG)} 
          value={QbUtils.checkTree(QbUtils.loadTree(this.props.rule), this.latestConfig(this.props.patientInputs, DEFAULT_CONFIG))}
          onChange={this.onChange}
          renderBuilder={this.renderBuilder}/>
      </div>
      <div className='p-grid p-justify-center'>
        {/* <Button 
          label='Cancel'
          className='p-button p-button-primary'
          onClick={() => this.handleCancel()}
          icon="pi pi-times" 
          iconPos="right"
          style={{'margin':'0px 8px'}}
          disabled={!this.state.editing}
          /> */}
        <Button 
          label='Save changes'
          className='p-button p-button-primary'
          onClick={() => this.handleSave(this.state.tree)}
          icon="pi pi-check" 
          iconPos="right"
          style={{'margin':'0px 8px'}}
          disabled={!this.state.editing}
          />
      </div>
    </div>
  )

  renderBuilder = (props) => (
    <div className="query-builder-container" style={{padding: '0px 10px'}}>
      <div className="query-builder">
          <Builder {...props} />
      </div>
    </div>
  )

  onChange = (immutableTree, config) => {
    if (this.state.loadIndicator > 0) {
      this.setState({
        tree: immutableTree, 
        config: config,
        editing: true,
      })
    } else {
      this.setState({
        tree: immutableTree, 
        config: config,
        loadIndicator: 1,
      })
    }
    // console.log("State tree: ", QbUtils.getTree(immutableTree));
  }

  handleEdit = () => {
    this.setState({editing: true})
  }

  handleSave = newRule => {
    this.props.saveRule(QbUtils.getTree(this.state.tree), this.props.priority)
    this.setState({editing: false})
  }

  handleCancel = () => {
    this.setState({
      editing: false,
      tree: QbUtils.checkTree(
        QbUtils.loadTree(this.props.rule), 
        this.latestConfig(this.props.patientInputs, DEFAULT_CONFIG)
      ),
    })
    console.log("Cancel edit: ", JSON.stringify(QbUtils.checkTree(QbUtils.loadTree(this.props.rule), this.latestConfig(this.props.patientInputs, DEFAULT_CONFIG))))
  }

  parseRule = rules => {
    var stringAccum = []
    // this._parseRule(rules, stringAccum)
    return (
      <div>
        {stringAccum.map(a => <p>{a}</p>)}
      </div>
    )
  }

  _parseRule = (rules, accumulator, level=0) => {
    let indent = level > 0 ? ' '.repeat(level) + '|-' : ''
    if ("children1" in rules) {
      accumulator.push(rules.properties.conjunction === "AND" ? `${indent}All of\n` : `${indent}Any of\n`)
      console.log(rules.properties.conjunction === "AND" ? `${indent}All of` : `${indent}Any of`)
      Object.values(rules.children1).forEach((a) => {
        this._parseRule(a, accumulator, level + 1)
      })
    } else {
      try {
        accumulator.push(`${indent}${rules.properties.field} ${rules.properties.operator} ${rules.properties.value}\n`)
        console.log(`${indent}${rules.properties.field} ${rules.properties.operator} ${rules.properties.value}`)
      } catch (e) {
        console.log("empty rule")
      }
    }
  }

  displayRules = tree => {
    // var stringAccum = []
    // this._parseRule(QbUtils.getTree(this.state.tree), stringAccum)

    var queryString = QbUtils.queryString(
      this.state.tree, 
      this.latestConfig(this.props.patientInputs, DEFAULT_CONFIG),
      true
    )
    if (queryString) {
      queryString = queryString.replace(/Symptoms./g, '')
      .replace(/Risk Factors./g, '')
      .replace(/Exposure./g, '')
      .replace(/Vital signs./g, '')
      .replace(/Patient./g, '')
    }
    return (<>{queryString}</>)
  }
}

const mapStateToProps = state => ({
  patientInputs: state.patientInputs
})

const mapDispatchToProps = dispatch => ({
  saveRule: (rule, priority) => dispatch(saveRule(rule, priority))
})

export default connect(mapStateToProps, mapDispatchToProps)(QueryBuilder)