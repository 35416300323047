import produce from "immer";
import {
  FETCH_TABLE_DATA_REQUEST,
  FETCH_TABLE_DATA_SUCCESS,
  FETCH_TABLE_DATA_FAILURE,
  UPDATE_PRIORITIES,
  UPDATE_PRIORITIES_FINISHED,
  UPDATE_PATIENT_ESR,
  UPDATE_ESR_REQUEST,
  UPDATE_ESR_SUCCESS,
  UPDATE_ESR_FAILURE,
} from "../Actions";

export const initialState = {
  patients: [],
  updating_priorities: false,
  fetching: false,
  error: null,
  esr_updating: false,
  totalPatients: null,
  countEmergency: null,
  countPriority1: null,
  countPriority2: null,
  countPriority3: null,
  countPriority4: null,
  countPriority5: null,
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_TABLE_DATA_REQUEST:
        draft.fetching = true;
        draft.error = null;
        break;
      case FETCH_TABLE_DATA_SUCCESS:
        draft.fetching = false;
        draft.patients = action.data;
        break;
      case FETCH_TABLE_DATA_FAILURE:
        draft.fetching = false;
        draft.patients = [];
        draft.error = action.error;
        break;
      case UPDATE_PRIORITIES:
        draft.updating_priorities = true;
        break;
      case UPDATE_PRIORITIES_FINISHED:
        draft.updating_priorities = false;
        draft.patients = action.data;
        draft.totalPatients = action.data.length;
        draft.countEmergency = action.data.filter(
          (p) => p.priority === 0
        ).length;
        draft.countPriority1 = action.data.filter(
          (p) => p.priority === 1
        ).length;
        draft.countPriority2 = action.data.filter(
          (p) => p.priority === 2
        ).length;
        draft.countPriority3 = action.data.filter(
          (p) => p.priority === 3
        ).length;
        draft.countPriority4 = action.data.filter(
          (p) => p.priority === 4
        ).length;
        draft.countPriority5 = action.data.filter(
          (p) => p.priority === 5
        ).length;
        break;
      case UPDATE_PATIENT_ESR:
        const index = draft.patients.findIndex(
          (each) => each.uid === action.data.uid
        );
        draft.patients[index][action.data.type][action.data.label] =
          action.data.value;
        break;
      case UPDATE_ESR_REQUEST:
        draft.esr_updating = true;
        break;
      case UPDATE_ESR_SUCCESS:
        draft.esr_updating = false;
        break;
      case UPDATE_ESR_FAILURE:
        draft.esr_updating = false;
        alert("Update failed");
        break;
      default:
        break;
    }
  });
