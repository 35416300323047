import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import Login from "./Routers/Login";
import Signup from "./Routers/Signup";
import Main from "./Routers/Main";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./layout/layout.scss";
import "./App.scss";

function App() {
  return (
    <AuthProvider>
      <Router>
        <PrivateRoute path="/" component={Main} />
        <Switch>
          <Route exact path="/Login" component={Login} />
          <Route exact path="/SignUp" component={Signup} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
