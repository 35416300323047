import produce from "immer";
import { Utils as QbUtils } from "react-awesome-query-builder";
import {
  SAVE_RULE_EMERGENCY,
  SAVE_RULE_PRIORITY1,
  SAVE_RULE_PRIORITY2,
  SAVE_RULE_PRIORITY3,
  SAVE_RULE_PRIORITY4,
  SAVE_RULE_PRIORITY5,
  RESET_RULES,
} from "../Actions";
import {
  EMERGENCY_DEFAULT,
  DEFAULT_PRIORITY_1,
  DEFAULT_PRIORITY_2,
  DEFAULT_PRIORITY_3,
  DEFAULT_PRIORITY_4,
} from "../components/rules/RuleDefaults";

export const initialState = {
  emergency: EMERGENCY_DEFAULT,
  priority1: DEFAULT_PRIORITY_1,
  priority2: DEFAULT_PRIORITY_2,
  priority3: DEFAULT_PRIORITY_3,
  priority4: DEFAULT_PRIORITY_4,
  priority5: { id: QbUtils.uuid(), type: "group" },
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SAVE_RULE_EMERGENCY:
        draft.emergency = action.rule;
        break;
      case SAVE_RULE_PRIORITY1:
        draft.priority1 = action.rule;
        break;
      case SAVE_RULE_PRIORITY2:
        draft.priority2 = action.rule;
        break;
      case SAVE_RULE_PRIORITY3:
        draft.priority3 = action.rule;
        break;
      case SAVE_RULE_PRIORITY4:
        draft.priority4 = action.rule;
        break;
      case SAVE_RULE_PRIORITY5:
        draft.priority5 = action.rule;
        break;
      case RESET_RULES:
        console.log("IM UPDATING STATE");
        draft.emergency = initialState.emergency;
        draft.priority1 = initialState.priority1;
        draft.priority2 = initialState.priority2;
        draft.priority3 = initialState.priority3;
        draft.priority4 = initialState.priority4;
        draft.priority5 = initialState.priority5;
        break;
      default:
        break;
    }
  });
