import { combineReducers } from "redux";
import configureStore from "./CreateStore";
import rootSaga from "../Sagas";

import todo from "./TodoReducer";
import rule from "./RuleReducer";
// import data from "./DataReducer";
import patientInputs from "./PatientInputReducer";
import profile from "./UserProfileReducer";
import tableData from "./TableDataReducer";
import personalChart from "./PersonalChartReducer";

export const reducers = combineReducers({
  profile,
  todo,
  rule,
  // data,
  patientInputs,
  tableData,
  personalChart,
});

export default () => {
  let finalReducers = reducers;
  let { store, sagasManager, sagaMiddleware, persistor } = configureStore(
    finalReducers,
    rootSaga
  );

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require(".").reducers;
      store.replaceReducer(nextRootReducer);
      const newYieldedSagas = require("../Sagas").default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }

  return { store, persistor };
};
