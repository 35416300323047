export const FETCH_TEST_DATA = "FETCH_TEST_DATA"
export const UPDATE_PRIORITIES = "UPDATE_PRIORITIES"
export const UPDATE_PRIORITIES_FINISHED = "UPDATE_PRIORITIES_FINISHED"
export const UPDATE_PATIENT_STATUS = "UPDATE_PATIENT_STATUS"

export function fetchTestData(data) {
  return {
    type: FETCH_TEST_DATA,
    data: data,
  }
}

export function updatingPriorities() {
  return {
    type: UPDATE_PRIORITIES
  }
}

export function updatePrioritiesFinished(data) {
  return {
    type: UPDATE_PRIORITIES_FINISHED,
    data: data
  }
}

export function updatePatientStatus(id, status) {
  return {
    type: UPDATE_PATIENT_STATUS,
    id: id,
    status: status,
  }
}