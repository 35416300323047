import React, {Component} from 'react';
import {connect} from 'react-redux';
import {DataTable} from 'primereact/datatable';
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import {InputTextarea} from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';
import {
  addPatientInput, 
  deletePatientInput
} from '../../Actions/PatientInputActions';

const FIELD_TYPES = [
  {value: 'boolean', label: 'Yes/No'},
  {value: 'text', label: 'Text'},
  {value: 'num', label: 'Number'}
]

class QuestionEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      label: '',
      question: '',
      active: false,
      type: null,
      editStatus: false,
      selectedPatient: null,
    }
  }

  handleAdd() {
    this.props.addPatientInput(this.props.type, {
      code: Math.random().toString(36).substring(7),
      label: this.state.label,
      question: this.state.question,
      active: this.state.active,
      type: this.state.type,
      category: this.props.type
    })
    this.setState({
      label: '',
      question: '',
      active: false,
    })
  }

  handleDelete() {
    if(this.state.selectedPatient) {
      this.props.deletePatientInput(this.props.type, this.state.selectedPatient)
    }
  }

  renderEditButtons() {
    return (
      <div className="p-grid p-justify-center">
        <Button 
          label='Add'
          className='p-button-raised p-button-rules'
          onClick={() => {
            // console.log(JSON.stringify(this.props.data))
            this.setState({editStatus: true})}
            }
          icon="pi pi-plus" 
          iconPos="left"
          style={{margin: '0px 10px'}}
          />
        <Button 
          label='Delete'
          className='p-button-raised p-button-rules'
          onClick={() => this.handleDelete()}
          icon="pi pi-minus" 
          iconPos="left"
          style={{margin: '0px 10px'}}
          />
      </div>
    )
  }

  renderEditRecord() {
    return (
      <div className="p-col">
        <p>Add New Question</p>
        <div className='p-grid p-justify-center'>
          <div className='p-col-3 p-lg-3 p-md-12 p-sm-12'>
            <span>Label</span>
            <InputText 
              value={this.state.label}
              onChange={(e) => this.setState({label: e.target.value})} 
              placeholder="Label" 
              style={{width: '100%'}}
              />
          </div>
          <div className='p-col-5 p-lg-5 p-md-12 p-sm-12'>
            <span>Question</span>
            <InputTextarea
              value={this.state.question}
              onChange={(e) => this.setState({question: e.target.value})} 
              placeholder="Question" 
              rows={2} cols={50}
              style={{width: '100%'}}
              />
          </div>
          <div className='p-col-2 p-lg-2 p-md-12 p-sm-12'>
            <span>Field Type</span>
            <Dropdown 
              value={this.state.type} 
              options={FIELD_TYPES} 
              onChange={(e) => {this.setState({type: e.value})}} 
              placeholder="Select"
              style={{width: '100%', minWidth: '50px'}}
              />
          </div>
          <div className='p-col-2 p-lg-1 p-md-12 p-sm-12'>
            <span>Active<br></br></span>
            <Checkbox 
              onChange={e => this.setState({active: e.checked})} 
              checked={this.state.active}></Checkbox>
          </div>
          <div className='p-col-1 p-lg-1 p-md-12 p-sm-12'>
            <Button 
              label='Add'
              className='p-button-raised p-button-rules'
              onClick={() => this.handleAdd()}
              style={{margin: '20px 10px 0px 10px'}}
            />
            <Button 
              label='Cancel'
              className='p-button-raised p-button-rules'
              onClick={() => this.setState({editStatus: false})}
              style={{margin: '10px 10px 0px 10px'}}
            />
          </div>
        </div>
      </div>
    )
  }

  typeBodyTemplate(rowData) {
    if (rowData.type === 'boolean') {
      return <span style={{textAlign: 'center', width: '100%'}}>Yes/No</span>
    } else {
      return rowData.type
    }
  }

  activeBodyTemplate(rowData) {
    return rowData.active ? 'Yes' : 'No'
  }

  render() {
    return (
      <div className="p-grid-dir-col p-align-end">
        { this.state.editStatus ? this.renderEditRecord() : null}
        { this.state.editStatus ? null : this.renderEditButtons()}
        <div className="p-col">
          <DataTable 
            dataKey="code"
            autoLayout={true} 
            value={this.props.data}
            selectionMode="single"
            selection={this.state.selectedPatient}
            onSelectionChange={(e) => this.setState({ selectedPatient: e.value })}>
            <Column field="label" header="Label" />
            <Column field="question" header="Question" />
            <Column field="type" header="Answer Type" body={this.typeBodyTemplate}/>
            <Column field="active" header="Active" body={this.activeBodyTemplate}/>
            {/* <Column rowEditor={true} /> */}
          </DataTable>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  addPatientInput: (type, input) => dispatch(addPatientInput(type, input)),
  deletePatientInput: (type, input) => dispatch(deletePatientInput(type, input)),
})

export default connect(null, mapDispatchToProps)(QuestionEditor);