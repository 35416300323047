import React from "react";
import { Scatter } from "react-chartjs-2";
import { Card } from "primereact/card";
import classNames from "classnames";
import { ProgressSpinner } from "primereact/progressspinner";

type LineChartProps = {
  chartTitle: string;
  data?: any;
  loading?: boolean;
  min_y?: number;
  main_color?: string;
  sencond_color?: string;
  third_color?: string;
};

function LineChart({
  chartTitle,
  data = [{ ts: null, value: 0 }],
  loading,
  min_y = 50,
  main_color = "rgba(75,192,192,1)",
  sencond_color = "rgba(75,192,192,0.4)",
  third_color = "#fff",
}: LineChartProps) {
  const processedData = data.map((each: any) => {
    return { t: new Date(each.ts), y: each.value };
  });

  // console.log(processedData[0].t);
  // console.log(processedData[processedData.length - 1].t);

  // const x_start = processedData[0].t;
  // console.log(x_start);
  // x_start.setDate(x_start.getDate() - 2);

  const chartData = {
    // labels: ["Scatter"],
    datasets: [
      {
        showLine: false,
        lineTension: 0.2,
        // fill: false,
        borderColor: main_color,
        backgroundColor: sencond_color,
        pointBorderColor: main_color,
        pointBackgroundColor: third_color,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: main_color,
        pointHoverBorderColor: third_color,
        pointHoverBorderWidth: 2,
        pointRadius: 3,
        pointHitRadius: 10,
        data: processedData,
      },
    ],
  };

  const chartOptions = {
    legend: {
      display: false,
      lineColor: "red",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: min_y,
          },
        },
      ],
      xAxes: [
        {
          type: "time",
          distribution: "linear",
          display: true,
          time: {
            parser: "MM/DD/YYYY HH:mm",
            tooltipFormat: "ll HH:mm",
            unit: "day",
            unitStepSize: 1,
            min: new Date(data[0].ts - 100000000),
            // max: new Date(data[data.length - 1].ts + 10000000000),
            displayFormats: {
              minute: "MMM DD",
              hour: "MMM DD",
              day: "MMM DD",
              week: "MMM DD",
              month: "MMM DD",
              year: "MMM DD",
            },
          },
        },
      ],
    },
  };

  return (
    <div
      className={classNames("p-col-6", "p-lg-6", {
        centered: loading,
        "vh-30": loading,
      })}
    >
      {loading ? (
        <div className="centered">
          <ProgressSpinner />
          {/* <h2 className="padding-30"> loading... </h2> */}
        </div>
      ) : (
        <Card title={chartTitle}>
          <Scatter options={chartOptions} data={chartData} />
        </Card>
      )}
    </div>
  );
}

export default LineChart;
