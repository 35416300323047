import React, { Component } from "react";
import classNames from "classnames";
import Topbar from "../components/home/Topbar";
import Footer from "../components/home/Footer";
import { Menu } from "../components/home/Menu";
import { Route, Redirect, Switch } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import PatientInputsPage from "../components/patientinputs/PatientInputsPage";
import RulesPage from "../components/rules/RulesPage";
import { Documentation } from "../components/Documentation";
import firebase from "firebase/app";
import "firebase/auth";
import { connect } from "react-redux";
import { fetchTableDataRequest } from "../Actions";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.createMenu();
  }

  componentDidMount() {
    this.props.fetchTableData();
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  createMenu() {
    this.menu = [
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        command: () => {
          window.location = "#/";
        },
        to: "/dashboard",
      },
      {
        label: "Define Triage Rules",
        icon: "pi pi-fw pi-sitemap",
        to: "/rules",
      },
      // {
      //   label: "Patient Data  Inputs",
      //   icon: "pi pi-fw pi-list",
      //   to: "/patient-inputs",
      // },
      {
        label: "Sign out",
        icon: "pi pi-fw pi-sign-out",
        command: () => {
          firebase.auth().signOut();
        },
      },
    ];
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  render() {
    const logo =
      this.state.layoutColorMode === "dark"
        ? "assets/layout/images/Patchd-Logo_Rev.png"
        : "assets/layout/images/logo.svg";

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
      "layout-sidebar-light": this.state.layoutColorMode === "light",
    });

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <Topbar onToggleMenu={this.onToggleMenu} />

        <div
          ref={(el) => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}
        >
          <div className="layout-logo">
            <img alt="Logo" src={logo} />
          </div>
          <Menu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
        </div>

        <div className="layout-main">
          <Switch>
            <Redirect exact from="/" to="dashboard" />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/rules" component={RulesPage} />
            <Route path="/patient-inputs" component={PatientInputsPage} />
            <Route path="/documentation" component={Documentation} />
          </Switch>
        </div>

        <Footer />

        <div className="layout-mask"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTableData: () => dispatch(fetchTableDataRequest()),
});

export default connect(null, mapDispatchToProps)(Home);
