export const DEFAULT_EXPOSURE = [
  {
    code: "8rd7y",
    type: "boolean",
    label: "COVID-19 diagnosis",
    question: "Have you been diagnosed with COVID-19?",
    category: "exposure",
    active: "Yes",
  },
  {
    code: "xxb26",
    label: "Contact with confirmed",
    question:
      "Have you had close contact with a person with confirmed COVID-19 infection in the past 14 days?",
    active: true,
    type: "boolean",
    category: "exposure",
  },
  {
    code: "ox71bh",
    label: "Healthcare worker",
    question:
      "Are you a healthcare worker who interacts with patients regularly?",
    active: true,
    type: "boolean",
    category: "exposure",
  },
  {
    code: "s12usn",
    label: "Risky location",
    question:
      "Have you spent time in a nursing home, dormitory, shelter, prison or dialysis center in the past 14 days?",
    active: true,
    type: "boolean",
    category: "exposure",
  },
  {
    code: "xo4f2b",
    label: "Travel risk",
    question:
      "In the last 14 days, have you returned from New York, New Jersey, or Connecticut, or from international travel?",
    active: true,
    type: "boolean",
    category: "exposure",
  },
];

export const DEFAULT_SYMPTOMS = [
  {
    code: "dphjgrr",
    type: "boolean",
    label: "Shortness of breath",
    question:
      "Are you having trouble breathing that is unusual for you? For example, are you having to stop and catch your breath more than usual while walking or going up stairs?",
    category: "symptoms",
    active: "Yes",
  },
  {
    code: "5ejcvr",
    label: "Chest pain",
    question:
      "Are you having continuous, severe pain or pressure in your chest (not just with coughing or breathing)?",
    active: true,
    type: "boolean",
    category: "symptoms",
  },
  {
    code: "w8i6pzd",
    label: "Bluish lips or face",
    question: "Is there an unusual blue color to your lips or face?",
    active: true,
    type: "boolean",
    category: "symptoms",
  },
  {
    code: "8sne7",
    label: "Severe weakness or dizziness",
    question: "Are you so weak or dizzy that you cannot stand?",
    active: true,
    type: "boolean",
    category: "symptoms",
  },
  {
    code: "sf8i7fe",
    label: "Sore throat",
    question: "Do you have a sore throat?",
    active: true,
    type: "boolean",
    category: "symptoms",
  },
  {
    code: "mfu3ryj",
    label: "Loss of taste",
    question: "Have you had a loss of smell or taste?",
    active: true,
    type: "boolean",
    category: "symptoms",
  },
  {
    code: "kjsqz5",
    label: "Severe shortness of breath",
    question:
      "Are you struggling to take each breath or having difficulty speaking because you are so short of breath?",
    active: true,
    type: "boolean",
    category: "symptoms",
  },
  {
    code: "tr172s",
    label: "Coughing",
    question: "Have you had a cough that is not usual for you?",
    active: true,
    type: "boolean",
    category: "symptoms",
  },
];

export const DEFAULT_RISK_FACTORS = [
  {
    code: "q67zsy",
    type: "boolean",
    label: "Pregnancy",
    question:
      "Are you pregnant or have you given birth within the last two weeks?",
    category: "risk factor",
    active: "Yes",
  },
  {
    code: "o2stsj",
    label: "Active cancer",
    question: "Do you currently have an active cancer?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "vvitnb",
    label: "Organ transplant",
    question: "Have you had an organ or bone marrow transplant?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "7z8a3m",
    label: "Autoimmune disorder",
    question:
      "Do you suffer from an autoimmune disorder like rheumatoid arthritis or lupus?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "n4mybn",
    label: "Corticosteroids",
    question:
      "Are you currently taking any corticosteroid pills or other medications that weaken your immune system?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "suhi18",
    label: "HIV",
    question: "Do you have HIV?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "8k1qtsp",
    label: "Respiratory disease",
    question:
      "Have you been told by a doctor that you have asthma, COPD, emphysema, or any other chronic lung disease?",
    active: false,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "jvynom",
    label: "CHF",
    question: "Have you had congestive heart failure (CHF) or a weak heart?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "llndrq",
    label: "Diabetes",
    question: "Do you have Type 1 or Type 2 diabetes?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "t7mgz",
    label: "Dialysis",
    question: "Do you have chronic kidney disease that requires dialysis?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "jedoki",
    label: "Cirrhosis",
    question: "Do you have cirrhosis of your liver?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
  {
    code: "1pswb",
    label: "Hypertension",
    question: "Do you have hypertension (high blood pressure)?",
    active: true,
    type: "boolean",
    category: "risk factors",
  },
];
