import { takeLatest, all, put } from "redux-saga/effects";
import {
  FETCH_TEMP_REQUEST,
  FETCH_TEMP_SUCCESS,
  FETCH_TEMP_FAILURE,
  FETCH_PULSEOX_REQUEST,
  FETCH_PULSEOX_SUCCESS,
  FETCH_PULSEOX_FAILURE,
} from "../Actions";
import { fetchChartTemp, fetchChartPulseOx } from "../Api/FirebasePatients";

export function* fetchTempChart() {
  yield takeLatest(FETCH_TEMP_REQUEST, _fetchTempChart);
}

export function* _fetchTempChart(action) {
  const raw_data = yield fetchChartTemp(action.uid);
  if (raw_data.error) {
    yield put({ type: FETCH_TEMP_FAILURE, error: raw_data.msg });
  } else {
    const proccess_temp = raw_data.data.map((x) => {
      return { ts: x.ts.toDate(), value: Number(x.reading) };
    });
    yield put({ type: FETCH_TEMP_SUCCESS, data: proccess_temp });
  }
}

export function* fetchPulseOxChart() {
  yield takeLatest(FETCH_PULSEOX_REQUEST, _fetchPulseOxChart);
}

export function* _fetchPulseOxChart(action) {
  const raw_data = yield fetchChartPulseOx(action.uid);
  if (raw_data.error) {
    yield put({ type: FETCH_PULSEOX_FAILURE, error: raw_data.msg });
  } else {
    const process_pulse = raw_data.data.map((x) => {
      return { ts: x.ts.toDate(), value: x.pulse };
    });
    const process_spo2 = raw_data.data.map((x) => {
      return { ts: x.ts.toDate(), value: x.spo2 };
    });

    yield put({
      type: FETCH_PULSEOX_SUCCESS,
      pulse_data: process_pulse,
      spo2_data: process_spo2,
    });
  }
}

export function* personalChartDataSaga() {
  yield all([fetchTempChart(), fetchPulseOxChart()]);
}
