import React from "react";
import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { updateESRRequest } from "../../Actions";
import { ScrollPanel } from "primereact/scrollpanel";
import classNames from "classnames";

function SymptomsCard(props) {
  const dispatch = useDispatch();
  const { userUid, data } = props;

  const displayData = Object.keys(data).map((key) => {
    return { label: key, value: data[key] };
  });

  const dropdownTemp = (option) => {
    return (
      <div
        className={classNames("drop-down-items", {
          yes: option.value,
          no: !option.value,
        })}
      >
        {option.label}
      </div>
    );
  };

  let options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  function onValueChange(e) {
    const data = {
      uid: userUid,
      type: "symptoms",
      label: e.target.name,
      value: e.value,
    };
    return dispatch(updateESRRequest(data));
  }

  return (
    <div className="card">
      <h1 style={{ fontSize: "16px" }}>Symptoms</h1>
      <ScrollPanel
        style={{
          width: "100%",
          height: "30vh",
        }}
        className="custom"
      >
        {displayData.map((each, i) => {
          return (
            <div className="p-grid" key={i}>
              <div className="p-col-10">{each.label}</div>
              <div className="p-col-2">
                <Dropdown
                  className={classNames("c-drop-down", {
                    "dd-yes": each.value,
                    "dd-no": !each.value,
                  })}
                  id={each.label}
                  name={each.label}
                  value={each.value}
                  options={options}
                  onChange={onValueChange}
                  itemTemplate={dropdownTemp}
                />
              </div>
            </div>
          );
        })}
      </ScrollPanel>
    </div>
  );
}

export default SymptomsCard;
