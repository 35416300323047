import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import app from "../firebase";
import { AuthContext } from "../Auth";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const _loginClick = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await app.auth().signInWithEmailAndPassword(email, password);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [email, history, password]
  );

  const _handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      try {
        await app.auth().signInWithEmailAndPassword(email, password);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    }
  };

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div className="p-grid p-justify-center dark" style={{ height: "100%" }}>
      <div className="p-col-11 p-md-4 centered">
        <div className="p-grid p-col-12 h-center">
          <Card
            subTitle="Login"
            title="Patchd at home Dashboard"
            className="radius-20"
            style={{ width: "100%" }}
          >
            <div className="p-grid p-fluid p-justify-center">
              <div className="p-col-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-user"></i>
                  </span>
                  <InputText
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid p-justify-center">
              <div className="p-col-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-lock"></i>
                  </span>
                  <Password
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    feedback={false}
                    onKeyDown={_handleKeyPress}
                  />
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid p-justify-center">
              <div className="p-col-12">
                <Button
                  label="Login"
                  className="p-button-rounded"
                  onClick={_loginClick}
                />
              </div>
            </div>
            <div className="p-grid p-fluid p-justify-center">
              <div className="p-col-12">
                <Button
                  label="Sign up"
                  className="p-button-rounded p-button-secondary"
                  onClick={() => {
                    history.push("./Signup");
                  }}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Login);
