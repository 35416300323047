import produce from 'immer';
import {
  ADD_EXPOSURE,
  ADD_SYMPTOMS,
  ADD_RISK_FACTORS,
  DELETE_EXPOSURE,
  DELETE_SYMPTOMS,
  DELETE_RISK_FACTORS,
  DEFAULT_PATIENT_INPUTS,
} from '../Actions'
import {
  DEFAULT_EXPOSURE,
  DEFAULT_SYMPTOMS,
  DEFAULT_RISK_FACTORS
} from '../components/patientinputs/Defaults';

const initialState = {
  exposure: DEFAULT_EXPOSURE,
  symptoms: DEFAULT_SYMPTOMS,
  riskFactors: DEFAULT_RISK_FACTORS, 
}

const addIfUnique = (newRule, existing) => {
  if (existing.some(r => r.label === newRule.label)) {
    return existing
  } else {
    return [...existing, newRule]
  }
}

export default (state = initialState, action) =>
  produce(state, draft => {
    switch(action.type) {
      case ADD_EXPOSURE:
        draft.exposure = addIfUnique(action.input, state.exposure)
        break;
      case ADD_SYMPTOMS:
        draft.symptoms = addIfUnique(action.input, state.symptoms)
        break;
      case ADD_RISK_FACTORS:
        draft.riskFactors = addIfUnique(action.input, state.riskFactors)
        break;
      case DELETE_EXPOSURE:
        draft.exposure = state.exposure.filter(input => 
          input.label !== action.input.label
        )
        break;
      case DELETE_SYMPTOMS:
        draft.symptoms = state.symptoms.filter(input => 
          input.label !== action.input.label
        )
        break;
      case DELETE_RISK_FACTORS:
        draft.riskFactors = state.riskFactors.filter(input => 
          input.label !== action.input.label
        )
        break;
      case DEFAULT_PATIENT_INPUTS:
        draft.exposure = initialState.exposure
        draft.symptoms = initialState.symptoms
        draft.riskFactors = initialState.riskFactors
        break;
      default: 
        break;
    }
  })