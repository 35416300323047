import { takeLatest, all, delay } from "redux-saga/effects";
import { ADD_TODO } from "../Actions";

export function* addTodo() {
  yield takeLatest(ADD_TODO, _addTodo);
}

export function* _addTodo() {
  yield delay(200);
  console.log("to-do-dodo-saga");
}

export function* todoSaga() {
  yield all([addTodo()]);
}
