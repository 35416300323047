import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import firebase from "firebase/app";
import "firebase/auth";

export const Loading = () => {
  return (
    <div className="p-grid p-justify-center dark" style={{ height: "100%" }}>
      <div className="p-col-11 p-md-4 centered">
        <Card
          title="Loading..."
          className="radius-20"
          style={{ width: "100%" }}
        >
          <div className="p-grid p-fluid centered">
            <div className="p-col-12 p-grid p-dir-col">
              <div className="p-grid padding-20">
                <ProgressSpinner />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export const LoadingWithCancel = () => {
  return (
    <div className="p-grid p-justify-center dark" style={{ height: "100%" }}>
      <div className="p-col-11 p-md-4 centered">
        <Card
          title="Loading..."
          className="radius-20"
          style={{ width: "100%" }}
        >
          <div className="p-grid p-fluid centered">
            <div className="p-col-12 p-grid p-dir-col">
              <div className="p-grid padding-20">
                <ProgressSpinner />
              </div>
              <Button
                className="p-button-rounded"
                label="Cancel"
                onClick={() => {
                  firebase.auth().signOut();
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export const AccessDenied = () => {
  return (
    <div className="p-grid p-justify-center dark" style={{ height: "100%" }}>
      <div className="p-col-11 p-md-4 centered">
        <Card
          title="Access denied"
          className="radius-20"
          subTitle="your account is not authorized to view dashboard"
        >
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <Button
                className="p-button-rounded"
                label="Log out"
                onClick={() => {
                  firebase.auth().signOut();
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
