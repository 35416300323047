import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";

const db = firebase.firestore();

export async function updateESR(uid, type, label, value) {
  try {
    let ref = db.collection("esrData").doc(uid);

    let exists = await ref.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        return true;
      } else {
        return false;
      }
    });
    if (exists) {
      var update = {};
      update[`${type}.${label}`] = value;
      ref.update(update);
    } else {
      ref.set({
        uid: uid,
        [type]: { [label]: value },
      });
    }

    return { error: false };
  } catch (e) {
    console.log(e);
    return { error: true, error_msg: String(e) };
  }
}
