export const ADD_EXPOSURE = 'ADD_EXPOSURE'
export const ADD_SYMPTOMS = 'ADD_SYMPTOMS'
export const ADD_RISK_FACTORS = 'ADD_RISK_FACTORS'
export const DELETE_EXPOSURE = 'DELETE_EXPOSURE'
export const DELETE_SYMPTOMS = 'DELETE_SYMPTOMS'
export const DELETE_RISK_FACTORS = 'DELETE_RISK_FACTORS'
export const DEFAULT_PATIENT_INPUTS = 'DEFAULT_PATIENT_INPUTS'

export function addPatientInput(type, input) {
  // console.log("AddPatientInput: ", type)
  switch(type) {
    case 'exposure':
      return ({
        type: ADD_EXPOSURE,
        input: input
      })
    case 'symptoms':
      return ({
        type: ADD_SYMPTOMS,
        input: input,
      })
    case 'risk factors':
      return ({
        type: ADD_RISK_FACTORS,
        input: input,
      })
    default:
      return
  }
}

export function deletePatientInput(type, input) {
  // console.log("DeletePatientInput: ", type)
  switch(type) {
    case 'exposure':
      return {
        type: DELETE_EXPOSURE,
        input: input,
      }
    case 'symptoms':
      return {
        type: DELETE_SYMPTOMS,
        input: input,
      }
    case 'risk factors':
      return {
        type: DELETE_RISK_FACTORS,
        input: input,
      }
    default:
      return
  }
}

export function restoreDefaultPatientInputs() {
  return {
    type: DEFAULT_PATIENT_INPUTS
  }
}