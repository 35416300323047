// import {BasicConfig} from 'react-awesome-query-builder'; // optional
// var InitialConfig = BasicConfig; // optional
import AntdConfig from "react-awesome-query-builder/lib/config/antd";
const InitialConfig = AntdConfig;

InitialConfig.conjunctions["AND"]["label"] = "All";
InitialConfig.conjunctions["OR"]["label"] = "Any";
InitialConfig.operators.equal.label = "Equals (=)";
InitialConfig.operators.greater.label = "Greater than (>)";
InitialConfig.operators.less.label = "Less than (<)";
InitialConfig.operators.greater_or_equal.label = "Greater than or equal to (≥)";
InitialConfig.operators.less_or_equal.label = "Less than or equal to (≤)";
InitialConfig.operators.not_equal.label = "Does not equal (≠)";
InitialConfig.settings.showNot = false;

const STATUS_OPTIONS = [
  { value: "emergent", title: "Emergent: Immediate Care" },
  { value: "care12", title: "Urgent: Care within 12 hours" },
  { value: "care24", title: "Non-Urgent: Care within 24 hours" },
  { value: "selfcare", title: "Low-Risk: Self-Care" },
];

// console.log("Initial Config: ", InitialConfig)

export const DEFAULT_CONFIG = {
  ...InitialConfig,
  fields: {
    patient: {
      type: "!struct",
      label: "Patient",
      subfields: {
        age: {
          label: "Age",
          type: "number",
          valueSources: ["value"],
          preferWidgets: ["number"],
        },
        status: {
          label: "Status",
          type: "select",
          valueSources: ["value"],
          listValues: STATUS_OPTIONS,
        },
        exposure: {
          label: "Any COVID-19 exposure",
          type: "boolean",
          valueSources: ["value"],
          excludeOperators: ["not_equal"],
        },
        symptoms: {
          label: "Any symptoms",
          type: "boolean",
          valueSources: ["value"],
          excludeOperators: ["not_equal"],
        },
        risk: {
          label: "Any risk factors",
          type: "boolean",
          valueSources: ["value"],
          excludeOperators: ["not_equal"],
        },
      },
    },
    vitals: {
      type: "!struct",
      label: "Vital signs",
      subfields: {
        temp_avg: {
          label: "Temperature 24h avg",
          type: "number",
          fieldSettings: {
            min: 85,
            max: 110,
          },
          valueSources: ["value"],
          preferWidgets: ["number"],
          excludeOperators: ["is_empty", "is_not_empty"],
        },
        temperatureMax24: {
          label: "Temperature (Max over 24h)",
          type: "number",
          fieldSettings: {
            min: 85,
            max: 110,
          },
          valueSources: ["value"],
          preferWidgets: ["number"],
          excludeOperators: ["is_empty", "is_not_empty"],
        },
        spo2_avg: {
          label: "SpO2 24h avg",
          type: "number",
          valueSources: ["value"],
          fieldSettings: {
            min: 10,
            max: 100,
          },
          preferWidgets: ["number"],
          excludeOperators: ["is_empty", "is_not_empty"],
        },
        spo2Min24: {
          label: "SpO2 (Min over 24h)",
          type: "number",
          valueSources: ["value"],
          fieldSettings: {
            min: 10,
            max: 100,
          },
          preferWidgets: ["number"],
          excludeOperators: ["is_empty", "is_not_empty"],
        },
        hr_avg: {
          label: "Pulse Rate 24h avg",
          type: "number",
          valueSources: ["value"],
          fieldSettings: {
            min: 30,
            max: 250,
          },
          preferWidgets: ["number"],
          excludeOperators: ["is_empty", "is_not_empty"],
        },
        // pulseMax24: {
        //   label: 'Pulse Rate (Max over 24h)',
        //   type: 'number',
        //   valueSources: ['value'],
        //   fieldSettings: {
        //       min: 30,
        //       max: 250,
        //   },
        //   preferWidgets: ['number'],
        //   excludeOperators: ['is_empty', 'is_not_empty'],
        // },
        feverDuration: {
          label: "Days with fever",
          type: "number",
          valueSources: ["value"],
          fieldSettings: {
            min: 0,
          },
          preferWidgets: ["number"],
          excludeOperators: ["is_empty", "is_not_empty"],
        },
      },
    },
  },
};
