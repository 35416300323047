import produce from "immer";
import { UPDATE_USER_PROFILE } from "../Actions";

export const initialState = {
  user_email: null,
  user_org: null,
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_USER_PROFILE:
        draft.user_email = action.email;
        draft.user_org = action.org;
        break;
      default:
        break;
    }
  });
