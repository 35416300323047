export const EMERGENCY_DEFAULT = {
  id: '9999aa8a-4567-489a-bcde-f171c918b683',
  type: 'group',
  children1: {
    'ab989a8b-0123-4456-b89a-b171ecd30914': {
      type: 'rule',
      properties: {
        field: 'symptoms.1',
        operator: 'equal',
        value: [true],
        valueSrc: ['value'],
        valueType: ['boolean'],
      },
      id: 'ab989a8b-0123-4456-b89a-b171ecd30914',
    },
    'aa8b8a98-4567-489a-bcde-f171ed008ae3': {
      type: 'rule',
      properties: {
        field: 'symptoms.6',
        operator: 'equal',
        value: [true],
        valueSrc: ['value'],
        valueType: ['boolean'],
      },
      id: 'aa8b8a98-4567-489a-bcde-f171ed008ae3',
    },
    'b99ba98a-0123-4456-b89a-b171ed00bc01': {
      type: 'rule',
      properties: {
        field: 'symptoms.2',
        operator: 'equal',
        value: [true],
        valueSrc: ['value'],
        valueType: ['boolean'],
      },
      id: 'b99ba98a-0123-4456-b89a-b171ed00bc01',
    },
  },
  properties: {conjunction: 'OR'},
};

export const DEFAULT_PRIORITY_1 = {
  id: 'b98a9899-89ab-4cde-b012-3171c90f9931',
  type: 'group',
  children1: {
    'ab88bbaa-4567-489a-bcde-f171c92901ac': {
      type: 'group',
      properties: {conjunction: 'AND'},
      children1: {
        '9989a9a8-4567-489a-bcde-f171c92a2be1': {
          type: 'group',
          properties: {conjunction: 'OR'},
          children1: {
            'b8b988a8-0123-4456-b89a-b171c92a2be1': {
              type: 'rule',
              properties: {
                field: 'vitals.temperatureMax24',
                operator: 'greater_or_equal',
                value: [100.4],
                valueSrc: ['value'],
                valueType: ['number'],
              },
              id: 'b8b988a8-0123-4456-b89a-b171c92a2be1',
            },
            '988bb9b8-89ab-4cde-b012-317210fb5779': {
              type: 'rule',
              properties: {
                field: 'symptoms.7',
                operator: 'equal',
                value: [true],
                valueSrc: ['value'],
                valueType: ['boolean'],
              },
              id: '988bb9b8-89ab-4cde-b012-317210fb5779',
            },
          },
          id: '9989a9a8-4567-489a-bcde-f171c92a2be1',
        },
        '99aaa999-4567-489a-bcde-f171ed14c046': {
          type: 'rule',
          properties: {
            field: 'riskFactors.3',
            operator: 'equal',
            value: [true],
            valueSrc: ['value'],
            valueType: ['boolean'],
          },
          id: '99aaa999-4567-489a-bcde-f171ed14c046',
        },
      },
      id: 'ab88bbaa-4567-489a-bcde-f171c92901ac',
    },
    'a8b99bb8-0123-4456-b89a-b171ed0e2838': {
      type: 'rule',
      properties: {
        field: 'symptoms.0',
        operator: 'equal',
        value: [true],
        valueSrc: ['value'],
        valueType: ['boolean'],
      },
      id: 'a8b99bb8-0123-4456-b89a-b171ed0e2838',
    },
    'aab9a999-cdef-4012-b456-7171ed0e5c10': {
      type: 'rule',
      properties: {
        field: 'symptoms.3',
        operator: 'equal',
        value: [true],
        valueSrc: ['value'],
        valueType: ['boolean'],
      },
      id: 'aab9a999-cdef-4012-b456-7171ed0e5c10',
    },
  },
  properties: {conjunction: 'OR'},
};

export const DEFAULT_PRIORITY_2 = {
  id: 'a8b9b9aa-0123-4456-b89a-b171ecbda40a',
  type: 'group',
  children1: {
    'bbbbabb8-0123-4456-b89a-b171ed1f47eb': {
      type: 'group',
      properties: {conjunction: 'AND'},
      children1: {
        'b9998889-89ab-4cde-b012-3171ed1f7016': {
          type: 'group',
          properties: {conjunction: 'OR'},
          children1: {
            'ababb99b-0123-4456-b89a-b171ed1f98cb': {
              type: 'rule',
              properties: {
                field: 'vitals.temperatureMax24',
                operator: 'greater_or_equal',
                value: [100.4],
                valueSrc: ['value'],
                valueType: ['number'],
              },
              id: 'ababb99b-0123-4456-b89a-b171ed1f98cb',
            },
            '8a8ba89b-4567-489a-bcde-f17210fbf1a4': {
              type: 'rule',
              properties: {
                field: 'symptoms.7',
                operator: 'equal',
                value: [true],
                valueSrc: ['value'],
                valueType: ['boolean'],
              },
              id: '8a8ba89b-4567-489a-bcde-f17210fbf1a4',
            },
          },
          id: 'b9998889-89ab-4cde-b012-3171ed1f7016',
        },
        'aa9aba8a-89ab-4cde-b012-3171ed20726d': {
          type: 'group',
          properties: {conjunction: 'OR'},
          children1: {
            '88a9889a-4567-489a-bcde-f171ed20726d': {
              type: 'rule',
              properties: {
                field: 'patient.age',
                operator: 'greater',
                value: [65],
                valueSrc: ['value'],
                valueType: ['number'],
              },
              id: '88a9889a-4567-489a-bcde-f171ed20726d',
            },
            '99aa9b8b-cdef-4012-b456-7171ed1f47eb': {
              type: 'rule',
              properties: {
                field: 'patient.risk',
                operator: 'equal',
                value: [true],
                valueSrc: ['value'],
                valueType: ['boolean'],
              },
              id: '99aa9b8b-cdef-4012-b456-7171ed1f47eb',
            },
            'b9888ba8-4567-489a-bcde-f171ed219e7e': {
              type: 'rule',
              properties: {
                field: 'patient.exposure',
                operator: 'equal',
                value: [true],
                valueSrc: ['value'],
                valueType: ['boolean'],
              },
              id: 'b9888ba8-4567-489a-bcde-f171ed219e7e',
            },
          },
          id: 'aa9aba8a-89ab-4cde-b012-3171ed20726d',
        },
      },
      id: 'bbbbabb8-0123-4456-b89a-b171ed1f47eb',
    },
    'b888bab8-0123-4456-b89a-b171ed21ea68': {
      type: 'rule',
      properties: {
        field: 'vitals.feverDuration',
        operator: 'greater_or_equal',
        value: [3],
        valueSrc: ['value'],
        valueType: ['number'],
      },
      id: 'b888bab8-0123-4456-b89a-b171ed21ea68',
    },
  },
  properties: {conjunction: 'OR'},
};

export const DEFAULT_PRIORITY_3 = {
  id: '88ab989b-cdef-4012-b456-7171ecbda40a',
  type: 'group',
  children1: {
    '8b8b988a-4567-489a-bcde-f171ed22f500': {
      type: 'group',
      properties: {conjunction: 'OR'},
      children1: {
        '9b998989-cdef-4012-b456-7171ed22a035': {
          type: 'rule',
          properties: {
            field: 'vitals.temperatureMax24',
            operator: 'greater_or_equal',
            value: [100.4],
            valueSrc: ['value'],
            valueType: ['number'],
          },
          id: '9b998989-cdef-4012-b456-7171ed22a035',
        },
        '98bbbbbb-89ab-4cde-b012-317210fc71fa': {
          type: 'rule',
          properties: {
            field: 'symptoms.7',
            operator: 'equal',
            value: [true],
            valueSrc: ['value'],
            valueType: ['boolean'],
          },
          id: '98bbbbbb-89ab-4cde-b012-317210fc71fa',
        },
      },
      id: '8b8b988a-4567-489a-bcde-f171ed22f500',
    },
    '8a9b8bbb-cdef-4012-b456-7171ed238c18': {
      type: 'group',
      properties: {conjunction: 'AND'},
      children1: {
        '9bbb8a8a-89ab-4cde-b012-3171ed238c18': {
          type: 'rule',
          properties: {
            field: 'patient.symptoms',
            operator: 'equal',
            value: [true],
            valueSrc: ['value'],
            valueType: ['boolean'],
          },
          id: '9bbb8a8a-89ab-4cde-b012-3171ed238c18',
        },
        'aabb9988-4567-489a-bcde-f171ed23a3f6': {
          type: 'rule',
          properties: {
            field: 'patient.risk',
            operator: 'equal',
            value: [true],
            valueSrc: ['value'],
            valueType: ['boolean'],
          },
          id: 'aabb9988-4567-489a-bcde-f171ed23a3f6',
        },
      },
      id: '8a9b8bbb-cdef-4012-b456-7171ed238c18',
    },
  },
  properties: {conjunction: 'OR'},
};

export const DEFAULT_PRIORITY_4 = {
  id: 'aa8b9abb-0123-4456-b89a-b17210fd13c8',
  type: 'group',
  children1: {
    '9bbb8a9b-89ab-4cde-b012-31721100734f': {
      type: 'rule',
      properties: {
        field: 'patient.symptoms',
        operator: 'equal',
        value: [true],
        valueSrc: ['value'],
        valueType: ['boolean'],
      },
      id: '9bbb8a9b-89ab-4cde-b012-31721100734f',
    },
    'baa89bab-4567-489a-bcde-f1721100994e': {
      type: 'rule',
      properties: {
        field: 'patient.risk',
        operator: 'equal',
        value: [true],
        valueSrc: ['value'],
        valueType: ['boolean'],
      },
      id: 'baa89bab-4567-489a-bcde-f1721100994e',
    },
    '9bbb89ba-0123-4456-b89a-b1721100b67a': {
      type: 'rule',
      properties: {
        field: 'patient.exposure',
        operator: 'equal',
        value: [true],
        valueSrc: ['value'],
        valueType: ['boolean'],
      },
      id: '9bbb89ba-0123-4456-b89a-b1721100b67a',
    },
  },
  properties: {conjunction: 'OR'},
};
