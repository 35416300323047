import { takeLatest, all, put, select, throttle } from "redux-saga/effects";
import {
  FETCH_TABLE_DATA_REQUEST,
  FETCH_TABLE_DATA_SUCCESS,
  FETCH_TABLE_DATA_FAILURE,
  UPDATE_PRIORITIES,
  UPDATE_PRIORITIES_FINISHED,
  SAVE_RULE_EMERGENCY,
  SAVE_RULE_PRIORITY1,
  SAVE_RULE_PRIORITY2,
  SAVE_RULE_PRIORITY3,
  SAVE_RULE_PRIORITY4,
  SAVE_RULE_PRIORITY5,
  RESET_RULES,
  UPDATE_ESR_REQUEST,
  UPDATE_ESR_SUCCESS,
  UPDATE_ESR_FAILURE,
  UPDATE_PATIENT_ESR,
} from "../Actions";
import { fetchTableData } from "../Api/FirebasePatients";
import { updateESR } from "../Api/FirebaseESRUpdate";
import { rule, patients, patientInputs, userOrg } from "./selectors";

function processData(raw, inputs) {
  let data = raw.map((pt) => {
    let clone = Object.assign({}, pt);
    if (pt.firstName == null && pt.lastName == null) {
      clone["name"] = "-";
    } else if (pt.firstName == null) {
      clone["name"] = pt.lastName + ", -";
    } else if (pt.lastName == null) {
      clone["name"] = "- , " + pt.firstName;
    } else {
      clone["name"] = pt.lastName + ", " + pt.firstName;
    }
    if (pt.DOB !== null) {
      clone["age"] =
        new Date().getFullYear() -
        new Date(pt.DOB._seconds * 1000).getFullYear();
    } else {
      clone["age"] = null;
    }
    if (pt.pulse_new == null) {
      clone["hr_latest"] = null;
      clone["hr_avg"] = null;
    } else {
      clone["hr_latest"] = pt.pulse_new;
      clone["hr_avg"] = pt.pulse_24_avg;
    }
    if (pt.temp_new == null) {
      clone["temp_latest"] = null;
      clone["temp_avg"] = null;
      clone["temperatureMax24"] = null;
    } else {
      clone["temp_latest"] = pt.temp_new;
      clone["temp_avg"] = pt.temp_24_avg;
      clone["temperatureMax24"] = pt.temp_24_max;
    }
    if (pt.spo2_new == null) {
      clone["spo2_latest"] = null;
      clone["spo2_avg"] = null;
      clone["spo2Min24"] = null;
    } else {
      clone["spo2_latest"] = pt.spo2_new;
      clone["spo2_avg"] = pt.spo2_24_avg;
      clone["spo2Min24"] = pt.spo2_24_min;
    }
    clone["priority"] = "priority5";

    let last_active;
    if (pt.temp_ts && pt.pulseOx_ts) {
      last_active = Math.max(pt.temp_ts._seconds, pt.pulseOx_ts._seconds);
    } else if (pt.temp_ts) {
      last_active = pt.temp_ts._seconds;
    } else if (pt.pulseOx_ts) {
      last_active = pt.pulseOx_ts._seconds;
    } else {
      last_active = null;
    }

    const days_last_active = Math.abs(
      Math.round(
        (new Date().getTime() - new Date(last_active * 1000).getTime()) /
          (1000 * 60 * 60 * 24)
      )
    );

    const allExposures = Object.assign(
      {},
      ...inputs.exposure.map((item) => ({ [item.label]: false }))
    );

    const allSymptoms = Object.assign(
      {},
      ...inputs.symptoms.map((item) => ({ [item.label]: false }))
    );

    const allRisks = Object.assign(
      {},
      ...inputs.riskFactors.map((item) => ({ [item.label]: false }))
    );

    clone["exposures"] = Object.assign(allExposures, clone.exposures);
    clone["symptoms"] = Object.assign(allSymptoms, clone.symptoms);
    clone["riskFactors"] = Object.assign(allRisks, clone.riskFactors);

    clone["days_last_active"] = days_last_active;
    clone["activity_status"] = getActivityStatus(days_last_active);

    delete clone["pulse_new"];
    delete clone["pulse_24_avg"];
    delete clone["temp_new"];
    delete clone["temp_24_avg"];
    delete clone["temp_24_max"];
    delete clone["spo2_new"];
    delete clone["spo2_24_avg"];
    delete clone["spo2_24_min"];
    return clone;
  });

  return data;
}

function getActivityStatus(days) {
  if (days < 1) {
    return "today";
  } else if (days < 7) {
    return "this_week";
  } else if (days < 14) {
    return "last_week";
  } else if (days < 31) {
    return "this_month";
  } else if (days < 365) {
    return "year";
  } else {
    return "inactive";
  }
}

export function* fetchDataTable() {
  yield takeLatest(FETCH_TABLE_DATA_REQUEST, _fetchDataTable);
}

export function* _fetchDataTable() {
  const org = yield select(userOrg);
  const data = yield fetchTableData(org);
  if (data.error) {
    yield put({ type: FETCH_TABLE_DATA_FAILURE, error: data.error_msg });
  } else {
    const inputs = yield select(patientInputs);
    yield put({
      type: FETCH_TABLE_DATA_SUCCESS,
      data: processData(data.data, inputs),
    });
  }
}

export function* updateTableWithPriorities() {
  yield takeLatest(FETCH_TABLE_DATA_SUCCESS, _updateTableWithPriorities);
}

export function* _updateTableWithPriorities(action) {
  yield put({ type: UPDATE_PRIORITIES });
  try {
    const priorities = yield select(rule);
    const inputs = yield select(patientInputs);
    const newData = yield calculateAllPriorities(
      priorities,
      action.data,
      inputs
    );
    yield put({ type: UPDATE_PRIORITIES_FINISHED, data: newData });
  } catch (e) {
    console.error(e);
  }
}

export function* updatePriorities() {
  yield takeLatest(SAVE_RULE_EMERGENCY, _updatePriorities);
  yield takeLatest(SAVE_RULE_PRIORITY1, _updatePriorities);
  yield takeLatest(SAVE_RULE_PRIORITY2, _updatePriorities);
  yield takeLatest(SAVE_RULE_PRIORITY3, _updatePriorities);
  yield takeLatest(SAVE_RULE_PRIORITY4, _updatePriorities);
  yield takeLatest(SAVE_RULE_PRIORITY5, _updatePriorities);
  yield takeLatest(RESET_RULES, _updatePriorities);
  yield throttle(1000, UPDATE_PATIENT_ESR, _updatePriorities);
}

export function* _updatePriorities() {
  yield put({ type: UPDATE_PRIORITIES });
  try {
    const patientData = yield select(patients);
    const priorities = yield select(rule);
    const inputs = yield select(patientInputs);
    const newData = yield calculateAllPriorities(
      priorities,
      patientData,
      inputs
    );
    yield put({
      type: UPDATE_PRIORITIES_FINISHED,
      data: newData,
    });
  } catch (e) {
    console.error(e);
  }
}

function* calculateAllPriorities(allPriorities, data, patientInputs) {
  const newData = yield data.map((patient) => {
    let clone = Object.assign({}, patient);
    clone["priority"] = calculatePriority(
      allPriorities,
      patient,
      patientInputs
    );
    return clone;
  });
  return newData;
}

function calculatePriority(rules, data, patientInputs) {
  if (checkRules(rules.emergency, data, patientInputs)) {
    return 0;
  } else if (checkRules(rules.priority1, data, patientInputs)) {
    return 1;
  } else if (checkRules(rules.priority2, data, patientInputs)) {
    return 2;
  } else if (checkRules(rules.priority3, data, patientInputs)) {
    return 3;
  } else if (checkRules(rules.priority4, data, patientInputs)) {
    return 4;
  } else {
    return 5;
  }
}

function checkRules(rules, patientData, patientInputs) {
  if (rules.hasOwnProperty("children1")) {
    let operator = rules.properties.conjunction;
    if (operator === "OR") {
      let accumulator = false;
      for (var r1 in rules.children1) {
        accumulator |= checkRules(
          rules.children1[r1],
          patientData,
          patientInputs
        );
      }
      return accumulator === 1;
    } else if (operator === "AND") {
      let accumulator = true;
      for (var r2 in rules.children1) {
        accumulator &= checkRules(
          rules.children1[r2],
          patientData,
          patientInputs
        );
      }
      return accumulator === 1;
    }
  } else {
    if (!rules.hasOwnProperty("properties")) {
      return false;
    }
    let field = fieldLookup(rules.properties.field, patientData, patientInputs);
    let value = rules.properties.value;
    return applyRule(field, rules.properties.operator, value);
  }
  return false;
}

function fieldLookup(fieldCode, data, inputs) {
  if (!fieldCode) {
    return null;
  }

  let [group, code] = fieldCode.split(".");

  switch (group) {
    case "symptoms":
      if (
        inputs.symptoms.hasOwnProperty(code) &&
        data.hasOwnProperty("symptoms")
      ) {
        let symptomLabel = inputs.symptoms[code].label;
        return data.symptoms[symptomLabel];
      } else if (!data.hasOwnProperty("symptoms")) {
        return false;
      }
      return null;
    case "riskFactors":
      if (
        inputs.riskFactors.hasOwnProperty(code) &&
        data.hasOwnProperty("riskFactors")
      ) {
        let riskFactorLabel = inputs.riskFactors[code].label;
        return data.riskFactors[riskFactorLabel];
      } else if (!data.hasOwnProperty("riskFactors")) {
        return false;
      }
      return null;
    case "exposures":
      if (
        inputs.exposure.hasOwnProperty(code) &&
        data.hasOwnProperty("exposures")
      ) {
        let exposureLabel = inputs.exposure[code].label;
        return data.exposures[exposureLabel];
      } else if (!data.hasOwnProperty("exposures")) {
        return false;
      }
      return null;
    case "vitals":
      if (data[code] !== null) {
        return data[code];
      }
      return null;
    case "patient":
      if (code !== "exposure" && code !== "symptoms" && code !== "risk") {
        return data[code];
      } else if (code === "exposure" && data.hasOwnProperty("exposures")) {
        return anyTrue(data.exposures);
      } else if (code === "symptoms" && data.hasOwnProperty("symptoms")) {
        return anyTrue(data.symptoms);
      } else if (code === "risk" && data.hasOwnProperty("riskFactors")) {
        let any = anyTrue(data.riskFactors);
        return any;
      } else if (
        !data.hasOwnProperty("exposure") ||
        !data.hasOwnProperty("symptoms") ||
        !data.hasOwnProperty("riskFactors")
      ) {
        return false;
      } else {
        console.log("null reach");
        return null;
      }
    default:
      return null;
  }
}

function anyTrue(data) {
  let any = Object.values(data).reduce((acc, val) => {
    acc |= val;
    return acc;
  }, false);
  return any === 1 ? true : false;
}

function applyRule(field, operator, value) {
  if (field !== null) {
    switch (operator) {
      case "equal":
        return field === value[0];
      case "not_equal":
        return field !== value[0];
      case "greater":
        return field > value[0];
      case "greater_or_equal":
        return field >= value[0];
      case "less":
        return field < value[0];
      case "less_or_equal":
        return field <= value[0];
      case "between":
        return (field > value[0]) & (field < value[1]);
      case "not_between":
        return !(field > value[0]) & (field < value[1]);
      default:
        return null;
    }
  } else {
    return null;
  }
}

export function* updateESRFirebase() {
  yield takeLatest(UPDATE_ESR_REQUEST, _updateESRFirebase);
}

export function* _updateESRFirebase(action) {
  const { uid, type, label, value } = action.data;
  const result = yield updateESR(uid, type, label, value);
  if (!result.error) {
    yield put({ type: UPDATE_PATIENT_ESR, data: action.data });
    yield put({ type: UPDATE_ESR_SUCCESS });
  } else {
    yield put({ type: UPDATE_ESR_FAILURE, error_msg: result.error_msg });
  }
}

export function* fetchTableDataSaga() {
  yield all([
    fetchDataTable(),
    updateTableWithPriorities(),
    updatePriorities(),
    updateESRFirebase(),
  ]);
}
