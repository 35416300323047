import React from "react";

type TopbarProp = {
  onToggleMenu: () => {};
};

function Topbar({ onToggleMenu }: TopbarProp) {
  return (
    <div className="layout-topbar clearfix">
      <button className="p-link layout-menu-button" onClick={onToggleMenu}>
        <span className="pi pi-bars" />
      </button>
    </div>
  );
}

export default Topbar;
