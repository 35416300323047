import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";

const db = firebase.firestore();

export async function fetchTableData(org) {
  try {
    const { data } = await firebase
      .functions()
      .httpsCallable("fetchPatientsTableData")({ org: org });

    if (data.error) {
      return { error: true, error_msg: data.data };
    } else {
      return { error: false, data: data.data };
    }
  } catch (e) {
    return { error: true, error_msg: e };
  }
}

export async function fetchChartTemp(uid) {
  try {
    const tempData = await db
      .collection("temperature")
      .where("uid", "==", uid)
      .limit(30)
      .orderBy("ts")
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((x) => x.data());
      });
    return { error: false, data: tempData };
  } catch (e) {
    console.error(e);
    return { error: true, msg: e };
  }
}

export async function fetchChartPulseOx(uid) {
  try {
    const pulseOxData = await db
      .collection("pulseOx")
      .where("uid", "==", uid)
      .limit(500)
      .orderBy("ts")
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((x) => x.data());
      });
    return { error: false, data: pulseOxData };
  } catch (e) {
    console.error(e);
    return { error: true, msg: e };
  }
}
