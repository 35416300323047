import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { updateUserProfile } from "../Actions";
import {
  LoadingWithCancel,
  AccessDenied,
} from "../components/main/MainScreenComponent";
import Home from "./Home";

var db = firebase.firestore();

function Main() {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(true);
  const [haveAccess, setAccessStatus] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        const user_uid = firebase.auth().currentUser.uid;
        let user = await db.collection("users").doc(user_uid).get();
        let org = await db
          .collection("users")
          .doc(user_uid)
          .collection("userData")
          .doc("organization")
          .get();
        const user_org_name = org.data().name;
        const { email, roles } = user.data();
        dispatch(updateUserProfile(email, user_org_name));
        if (roles.editor) {
          setAccessStatus(true);
        } else {
          setAccessStatus(false);
        }
        setPending(false);
      } catch (e) {
        console.log(e);
      }
    };
    getUser();
  }, [dispatch]);

  if (pending) {
    return <LoadingWithCancel />;
  }

  if (!haveAccess) {
    return <AccessDenied />;
  }

  return <Home />;
}

export default Main;
